import {
  Alert,
  Col,
  Row,
  Spin,
  Card,
} from 'antd'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'

import RaffleUtils from '../../utils/raffle'
import SiteMetaData from '../Confirmation/SiteMetaData'

import EntrantForm from '../EntrantForm'
import BackerForm from '../BackerForm'
import NewRaffleDescription from './NewRaffleDescription'

const RaffleDetails = ({
  email,
  onViewChange,
  raffle,
  view,
  refcode,
  refid,
  FormStep,
  previousPayments,
  oldCustomer,
  reEnterConfirmation,
  selectProduct,
}) => {
  const { siteUrl } = SiteMetaData()

  const renderView = () => {
    // scroll to (0, 0) on view change
    window.scroll({ top: 0, left: 0 })
    if (view === 'loading') {
      return (
        <Col md={24}>
          <Card className="center">
            <Spin tip="Loading..." />
          </Card>
        </Col>
      )
    }

    if (view === 'details') {
      return (
        <NewRaffleDescription raffle={raffle} refcode={refcode} onViewChange={onViewChange} />
      )
    }

    if (view === 'form') {
      return (
        <Row type="flex" justify="center" style={{ border: '1px solid #f0f0f0' }} className="mt2 raffle-details">
          <Col xs={24} md={22} className="details p1">
            <Row type="flex" justify="center">
              <Col xs={24} md={16} className="title center">
                <span className="ml1">{raffle.title}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={18}>
            <EntrantForm
              email={email}
              onViewChange={onViewChange}
              raffle={raffle}
              refcode={refcode}
              refid={refid}
              FormStep={FormStep}
              previousPayments={previousPayments}
              oldCustomer={oldCustomer}
              reEnterConfirmation={reEnterConfirmation}
              selectProduct={selectProduct}
            />
          </Col>
        </Row>
      )
    }

    if (view === 'backerform') {
      return (
        <Row type="flex" justify="center" style={{ border: '1px solid #f0f0f0' }} className="mt2 raffle-details">
          <Col xs={24} md={22} className="details p1">
            <Row type="flex" justify="center">
              <Col xs={24} md={16} className="title center">
                <span className="ml1">{raffle.title}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={18}>
            <BackerForm
              email={email}
              onViewChange={onViewChange}
              raffle={raffle}
              refcode={refcode}
              refid={refid}
              FormStep={FormStep}
              previousPayments={previousPayments}
              oldCustomer={oldCustomer}
              reEnterConfirmation={reEnterConfirmation}
              selectProduct={selectProduct}
            />
          </Col>
        </Row>
      )
    }

    return null
  }

  return (
    <>
      {/* override meta settings in the basic layout component */}
      <Helmet
        meta={[
          { name: 'description', content: RaffleUtils.getPlainDescription(raffle) },
          { name: 'keywords', content: raffle.title },

          { property: 'og:description', content: RaffleUtils.getPlainDescription(raffle) },
          { property: 'og:image', content: RaffleUtils.getImageSrc(raffle) },
          { property: 'og:title', content: raffle.title },
          { property: 'og:url', content: siteUrl + RaffleUtils.getDetailsUrl(raffle) },
          { property: 'twitter:image:src', content: RaffleUtils.getImageSrc(raffle) },
          { property: 'twitter:title', content: raffle.title },
          { property: 'twitter:description', content: RaffleUtils.getPlainDescription(raffle) },
        ]}
        title={raffle.title}
      />
      <Row type="flex" justify="center">
        {raffle.status === 'draft' && (
          <div className="mb2">
            <Alert
              description={<FormattedMessage id="comp.raffledetails.draft.desc" />}
              message={<FormattedMessage id="comp.raffledetails.draft.message" />}
              showIcon
              type="warning"
            />
          </div>
        )}
        {!RaffleUtils.isPublished(raffle) && (
          <div className="mb2">
            {RaffleUtils.isUnsuccessful(raffle) && (
              <Alert
                description={(
                  <Link state={{ industry: raffle.industry }} to={`/${raffle.industry}/`}>
                    <FormattedMessage id="comp.raffledetails.over.desc" />
                  </Link>
                )}
                message={(
                  <FormattedMessage
                    id="comp.raffledetails.backer.over.message"
                  />
)}
                showIcon
                type="info"
              />
            )}
            {RaffleUtils.isSuccessful(raffle) && (
              <Alert
                description={(
                  <Link state={{ industry: raffle.industry }} to={`/${raffle.industry}/`}>
                    <FormattedMessage id="comp.raffledetails.over.desc" />
                  </Link>
                )}
                message={(
                  <FormattedMessage
                    id={`comp.raffledetails.${RaffleUtils.isPaid(raffle) ? 'contest' : 'giveaway'}.over.message`}
                  />
)}
                showIcon
                type="info"
              />
            )}
          </div>
        )}
      </Row>
      {RaffleUtils.isPublished(raffle) && (
        <div>
          {renderView()}
        </div>
      )}
    </>
  )
}

RaffleDetails.propTypes = {
  email: PropTypes.string,
  onViewChange: PropTypes.func.isRequired,
  raffle: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
  refcode: PropTypes.string,
  refid: PropTypes.string,
  FormStep: PropTypes.number.isRequired,
  previousPayments: PropTypes.object.isRequired,
  oldCustomer: PropTypes.object.isRequired,
  reEnterConfirmation: PropTypes.string,
  selectProduct: PropTypes.object.isRequired,
}

RaffleDetails.defaultProps = {
  email: undefined,
  refcode: '',
  refid: '',
  reEnterConfirmation: '',
}

export default RaffleDetails
