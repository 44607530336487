import React, { useState } from 'react'
import {
  Alert,
  Form,
  Input,
  message,
  Button,
  Select,
  Divider,
} from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { capitalize } from 'lodash'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import RaffleActions from '../../actions/raffles'
import RaffleUtils from '../../utils/raffle'
import { countries, states } from '../../constants/raffle'

import CardSection from './CardSection'
import Cards from './Cards'
import TrustSeals from '../TrustSeals'

const CheckoutForm = ({
  onStepChange, raffle, product, customer, goBack, step,
}) => {
  const [country, setCountry] = useState('United States')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('Card Info is Required')
  const stripe = useStripe()
  const elements = useElements()

  const handleChangeCountry = (value) => {
    setCountry(value)
  }

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.empty) {
      setError('Card Info is Required')
    } else if (event.error) {
      setError(event.error.message)
    } else if (event.complete) {
      setError(null)
    }
  }

  const handleBack = () => {
    goBack()
  }

  const handleSubmit = async (values) => {
    try {
      if (!stripe || !elements) {
        return
      }
      if (!error) {
        setLoading(true)
        const client = await RaffleActions.handlePayment(raffle, product, customer)
        const clientSecret = client.id
        stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: values.name,
              address: {
                line1: values.line1,
                line2: values.line2 || '',
                city: values.city,
                state: values.state,
                postal_code: values.zip,
                country: countries[values.country],
              },
            },
          },
        }).then((result) => {
          setLoading(false)
          if (result.error) {
            // Show error to your customer
            message.error(result.error.message)
            RaffleActions.setUpToken(raffle.id, customer.email, customer.type)
          } else if (result.paymentIntent.status === 'succeeded') {
            message.success('Payment success')
            localStorage.removeItem('payment_hash') // Removing token if stored
            let messageId = ''
            if (RaffleUtils.isBackerCampaign(raffle)) {
              messageId = 'comp.confirmation.live.backer.contest.refer'
            }
            onStepChange({ step: step + 1, messageId })
          } else if (result.paymentIntent.status === 'requires_capture') {
            message.success('Pre-Authorize success')
            localStorage.removeItem('payment_hash') // Removing token if stored
            onStepChange({ step: step + 1 })
          } else {
            message.warn(result.paymentIntent.status)
            RaffleActions.setUpToken(raffle.id, customer.email, customer.type)
          }
        })
      }
    } catch (ex) {
      message.warn(ex.message)
    }
  }

  return (
    <div>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item>
          <Alert
            message={(
              <FormattedMessage id={`comp.rafflepayment.${RaffleUtils.isBackerCampaign(raffle)
                ? 'backer' : 'live'}.alert`}
              />
            )}
            showIcon
            type="info"
          />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="comp.rafflepayment.name" />}
          name="name"
          rules={[{ required: true }]}
        >
          <Input maxLength={128} size="large" />
        </Form.Item>
        <Form.Item label="Card details">
          <CardSection onChange={handleChange} />
          {error && (
            <div style={{ color: 'red' }} role="alert">
              *
              {error}
            </div>
          )}
          <Cards />
          <div style={{ clear: 'both' }} />
          <TrustSeals />
        </Form.Item>
        <Divider orientation="left">
          <FormattedMessage id="comp.rafflepayment.billing" />
        </Divider>
        <Form.Item
          label={<FormattedMessage id="common.address.line1" />}
          name="line1"
          rules={[{ required: true }]}
        >
          <Input maxLength={128} size="large" />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="common.address.line2" />}
          name="line2"
        >
          <Input maxLength={128} size="large" />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="common.address.city" />}
          name="city"
          rules={[{ required: true }]}
        >
          <Input maxLength={128} size="large" />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="common.address.state" />}
          name="state"
          rules={[{ required: true }]}
        >
          <Select showSearch>
            {states[country].map((t) => (
              <Select.Option key={t} value={t}>
                {t}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {country === 'Canada' && (
          <Form.Item
            label={<FormattedMessage id="common.address.zip" />}
            name="zip"
            normalize={(value) => value.toUpperCase()}
            rules={[{
              required: true,
            },
            {
              pattern: new RegExp(/^([a-zA-Z]\d[a-zA-Z]) {0,1}(\d[a-zA-Z]\d)$/),
              message: 'Invalid Postal Code',
            },
            ]}
          >
            <Input maxLength={128} size="large" />
          </Form.Item>
        )}
        {country === 'United States' && (
          <Form.Item
            label={<FormattedMessage id="common.address.zip" />}
            name="zip"
            normalize={(value) => capitalize(value)}
            rules={[{
              required: true,
            },
            {
              pattern: new RegExp(/^\d{5}$/),
              message: 'Invalid Zip Code',
            },
            ]}
          >
            <Input maxLength={128} size="large" />
          </Form.Item>
        )}
        <Form.Item
          initialValue={country}
          label={<FormattedMessage id="common.address.country" />}
          name="country"
          rules={[{ required: true }]}
        >
          <Select onChange={handleChangeCountry} showSearch>
            {Object.keys(countries).map((t) => (
              <Select.Option key={t} value={t}>
                <FormattedMessage id={`common.address.country.${t}`} />
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button.Group size="large">
            <Button icon={<LeftOutlined />} onClick={handleBack}>
              <FormattedMessage id="comp.entrantform.back" />
            </Button>
            <Button loading={loading} type="primary" htmlType="submit" block={false}>
              <span>
                <FormattedMessage
                  id={`common.${RaffleUtils.isBackerCampaign(raffle) ? 'pre-auth' : 'pay'}`}
                  values={{ amount: product.price }}
                />
              </span>
              <span className="pl1 xs-hide">
                <FormattedMessage
                  id="common.and-finish"
                  values={{ amount: product.price }}
                />
              </span>
            </Button>
          </Button.Group>
        </Form.Item>
      </Form>
    </div>
  )
}

CheckoutForm.propTypes = {
  onStepChange: PropTypes.func.isRequired,
  raffle: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
}

export default CheckoutForm
