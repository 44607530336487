import { LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Form,
  Input,
  Tooltip,
  message,
  Card,
} from 'antd'
import { capitalize, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import RaffleActions from '../../actions/raffles'
import RaffleUtils from '../../utils/raffle'

import Advertisement from '../Advertisement'
import BirthdateInput from '../BirthdateInput'
import GenderRadio from '../GenderRadio'
import SubmitButton from '../SubmitButton'

import QuestionInput from './QuestionInput'
import Recaptcha from '../Recaptcha'

const PersonalInfo = ({
  email, onStepChange, raffle, refcode, customer,
}) => {
  const [loading, setLoading] = useState(false)
  const [seconds, setSeconds] = useState(15)
  const [showAd, setShowAd] = useState(false)

  const intl = useIntl()

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      setSeconds((currentSeconds) => currentSeconds - 1)
    }, 1000)

    const timeoutTimer = setTimeout(() => {
      setShowAd(false)
    }, 15 * 1000)

    return () => {
      clearInterval(intervalTimer)
      clearTimeout(timeoutTimer)
    }
  }, [])

  const handleBack = () => {
    onStepChange({ step: -1 })
  }

  const handleFinish = async (values) => {
    const entrant = { ...values, email }

    try {
      setLoading(true)
      /** Cleaning the json to store questions */
      entrant.questions = []
      if (!isEmpty(raffle.questions)) {
        raffle.questions.forEach((question) => {
          entrant.questions.push(entrant[`questions[${raffle.questions.indexOf(question)}]`])
          delete entrant[`questions[${raffle.questions.indexOf(question)}]`]
        })
      }
      const resp = await RaffleActions.updateEntrant(raffle, entrant)
      const refid = resp.referralId
      setLoading(false)
      onStepChange({
        step: 1,
        customer: { ...entrant, type: 'entrant', shipping: customer.shipping || {} },
        refid,
      })
    } catch (ex) {
      message.warn(ex.message)
    }
  }

  if (showAd) {
    return (
      <>
        <FormattedMessage id="comp.entrantform.form-after-ad" values={{ seconds }} />
        <Advertisement unit="video" />
      </>
    )
  }

  return (
    <Card>
      <Form layout="vertical" onFinish={handleFinish}>
        <Form.Item>
          <Alert
            message={(
              <FormattedMessage
                id={`comp.entrantform.alert-${RaffleUtils.isPaid(raffle) ? 'contest' : 'giveaway'}`}
                values={{ type: RaffleUtils.isBackerCampaign(raffle) ? 'Backer' : 'Entrant' }}
              />
)}
            showIcon
            type="info"
          />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="common.email" />}
        >
          <span>
            {email}
          </span>
        </Form.Item>
        {!RaffleUtils.isPaid(raffle) && (
          <Form.Item
            label={<FormattedMessage id="common.referencecode" />}
            name="refcode"
            initialValue={customer.referredBy || refcode}
          >
            <Input maxLength={128} size="large" />
          </Form.Item>
        )}
        <Form.Item
          initialValue={customer.firstname || ''}
          label={<FormattedMessage id="common.firstname" />}
          name="firstname"
          normalize={(value) => capitalize(value)}
          rules={[{ required: true }]}
        >
          <Input maxLength={128} size="large" />
        </Form.Item>
        <Form.Item
          initialValue={customer.lastname || ''}
          label={<FormattedMessage id="common.lastname" />}
          name="lastname"
          normalize={(value) => capitalize(value)}
          rules={[{ required: true }]}
        >
          <Input maxLength={128} size="large" />
        </Form.Item>
        <Form.Item
          initialValue={customer.gender || ''}
          label={<FormattedMessage id="common.gender" />}
          name="gender"
          rules={[{ required: true }]}
        >
          <GenderRadio />
        </Form.Item>
        <Form.Item
          initialValue={customer.birthdate || ''}
          label={(
            <span>
              <FormattedMessage id="common.birthdate" />
              <Tooltip
                className="ml1"
                title={<FormattedMessage id="common.birthdate-must-18" />}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
        )}
          name="birthdate"
          rules={[{
            message: intl.formatMessage({ id: 'common.birthdate-must-18' }),
            required: true,
            type: 'number',
          }]}
        >
          <BirthdateInput />
        </Form.Item>
        {!isEmpty(raffle.questions) && (
          raffle.questions.map((question, index) => (
            <Form.Item
              key={question.question}
              name={`questions[${index}]`}
              rules={[{ required: true }]}
            >
              <QuestionInput question={question} />
            </Form.Item>
          ))
        )}
        <Form.Item
          name="recaptcha"
          rules={[{ required: true }]}
        >
          <Recaptcha />
        </Form.Item>
        <Form.Item>
          <Button.Group size="large">
            <Button icon={<LeftOutlined />} onClick={handleBack}>
              <span>
                <FormattedMessage id="comp.entrantform.back" />
              </span>
            </Button>
            {RaffleUtils.isPaid(raffle) && (
              <SubmitButton
                loading={loading}
                messageId="comp.next-step"
              />
            )}
            {!RaffleUtils.isPaid(raffle) && (
              <SubmitButton
                loading={loading}
                messageId="comp.entrantform.enter-giveaway"
              />
            )}
          </Button.Group>
        </Form.Item>
      </Form>
    </Card>
  )
}

PersonalInfo.propTypes = {
  email: PropTypes.string.isRequired,
  onStepChange: PropTypes.func.isRequired,
  raffle: PropTypes.object.isRequired,
  refcode: PropTypes.string,
  customer: PropTypes.object.isRequired,
}

PersonalInfo.defaultProps = {
  refcode: '',
}

export default PersonalInfo
