import { Collapse, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import React from 'react'

const ContestDetails = () => {
  const descriptions = [
    'Raffskie designed a game that utilizes the basic calculations we make in everyday life.',
    'The Game consists of skills relating to basic math, like addition, subtraction, multiplication '
      + 'and division.',
    'Objective of the game is to determine which side is greater.',
    'Each level has 5 questions to be answered.',
    'Answering all 5 questions correctly only allows you to go to the next level.',
    'All Winners are selected by having the most correct answers in the shortest amount of time.',
    'In the case of a tie, Raffskie might require the finalists to participate in a contest judged by '
      + 'a Raffskie representative. Contest examples could be a Photo Contest, Caption Contest, Selfie '
      + 'Contest or maybe a Short Story Contest on why winning the grand prize will have a significant '
      + 'impact in their life.',
  ]

  const rules = [
    'Do not close the browser before completing your chance(s).',
    'Answer all questions correctly in order to get to the next level.',
    'Make sure that you have seen the demo of the game before you start.',
  ]

  return (
    <Collapse>
      <Collapse.Panel
        header={<FormattedMessage id="comp.contestdetails.header" />}
        key={<FormattedMessage id="comp.contestdetails.header" />}
      >
        <Typography.Text strong>Description</Typography.Text>
        <ul>
          {descriptions.map((description) => (
            <li key={description}>
              {description}
            </li>
          ))}
        </ul>
        <Typography.Text strong>Rules</Typography.Text>
        <ul>
          {rules.map((rule) => (
            <li key={rule}>
              {rule}
            </li>
          ))}
        </ul>
      </Collapse.Panel>
    </Collapse>
  )
}

export default ContestDetails
