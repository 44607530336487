import { CopyOutlined } from '@ant-design/icons'
import {
  Button,
  Result,
  Row,
  Typography,
  message,
} from 'antd'
import PropTypes from 'prop-types'
import React, { useRef, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'

import RaffleUtils from '../../utils/raffle'

import SiteMetaData from './SiteMetaData'

const Confirmation = ({
  confirmationMessage,
  raffle,
  reEnterConfirmation,
  refid,
}) => {
  const myShareLink = useRef()
  const [display, setDisplay] = useState('display-none')
  const { siteUrl } = SiteMetaData()
  const iconSize = 50
  const shareUrl = `${siteUrl}${RaffleUtils.getDetailsUrl(raffle)}&refcode=${refid}`
  const style = { cursor: 'pointer' }
  const copyLinkStyle = { position: 'absolute', left: '-10000px' }
  const { Title } = Typography
  const textStyle = { textAlign: 'center', fontWeight: 'bold' }

  useEffect(() => {
    if (display === 'display-block') {
      const el = myShareLink.current
      el.select()
      document.execCommand('copy', false, null)
      setDisplay('display-none')
      message.success('Copied!')
    }
  }, [display])

  const copyURLToClipboard = () => {
    setDisplay('display-block')
  }

  const renderReferAFriend = () => {
    if (RaffleUtils.isBackerCampaign(raffle)) {
      if (RaffleUtils.isPaid(raffle)) {
        if (confirmationMessage !== '') {
          return (
            <FormattedMessage
              id={confirmationMessage}
              values={{
                br: <br />,
                minBackers: raffle.minBackers,
                backerCount: raffle.backerCount === 0 ? 1 : raffle.backerCount,
              }}
            />
          )
        }
        return (
          <FormattedMessage
            id={`comp.confirmation.backer.contest.refer${raffle.backerCount === 0 ? 0 : ''}`}
            values={{
              br: <br />,
              minBackers: raffle.minBackers,
              backerCount: raffle.backerCount === 0 ? 1 : raffle.backerCount,
            }}
          />
        )
      }
      return (
        <FormattedMessage
          id={`comp.confirmation.backer.giveaway.refer${raffle.backerCount === 0 ? 0 : ''}`}
          values={{
            br: <br />,
            minBackers: raffle.minBackers,
            backerCount: raffle.backerCount === 0 ? 1 : raffle.backerCount,
          }}
        />
      )
    }
    if (RaffleUtils.isPaid(raffle)) {
      return <FormattedMessage id="comp.confirmation.live.contest.refer" />
    }
    return <FormattedMessage id="comp.confirmation.live.giveaway.refer" />
  }

  const renderSubTitle = () => {
    if (reEnterConfirmation !== '') {
      return (
        <FormattedMessage id={reEnterConfirmation} />
      )
    }
    return (<FormattedMessage id="comp.raffledetails.success.subtitle" />)
  }

  const renderTitle = () => {
    if (reEnterConfirmation !== '') {
      return (
        <FormattedMessage
          id="comp.raffledetails.success.reenter.title"
          values={{ type: RaffleUtils.isBackerCampaign(raffle) ? 'a Backer' : 'an Entrant' }}
        />
      )
    }
    return (<FormattedMessage id="comp.raffledetails.success.title" />)
  }

  return (
    <Result
      extra={[
      ]}
      status="success"
      subTitle={renderSubTitle()}
      title={renderTitle()}
    >
      {(RaffleUtils.isLiveGiveaway(raffle) || RaffleUtils.isBackerCampaign(raffle)) && (
      <>
        <Title style={{ textAlign: 'center', color: '#1890ff' }} level={4}>Refer-A-Friend</Title>
        <Row className="center" type="flex" justify="center" style={textStyle}>
          {renderReferAFriend()}
        </Row>
        <Row className="center" type="flex" justify="center">
          <p style={{ textAlign: 'center' }}>
            <FacebookShareButton
              quote={raffle.title}
              style={style}
              url={shareUrl}
              key="facebook"
            >
              <FacebookIcon size={iconSize} round />
            </FacebookShareButton>
            <TwitterShareButton
              style={style}
              title={raffle.title}
              url={shareUrl}
              key="twitter"
            >
              <TwitterIcon size={iconSize} round />
            </TwitterShareButton>
            <PinterestShareButton
              description={raffle.title}
              media={RaffleUtils.getImageSrc(raffle)}
              style={style}
              url={shareUrl}
              key="pinterest"
            >
              <PinterestIcon size={iconSize} round />
            </PinterestShareButton>
            <EmailShareButton
              description={raffle.title}
              media={RaffleUtils.getImageSrc(raffle)}
              style={style}
              url={shareUrl}
              key="email"
            >
              <EmailIcon size={iconSize} round />
            </EmailShareButton>
          </p>
        </Row>
        <Row className="center" type="flex" justify="center">
          <p style={textStyle}>
            Or click below and share
            {' '}
            <br />
            <textarea ref={myShareLink} className={display} style={copyLinkStyle} value={shareUrl} readOnly />
            <Button className="mt1" onClick={() => copyURLToClipboard()}>
              <CopyOutlined />
              Copy Link
            </Button>
          </p>
        </Row>
      </>
      )}
    </Result>
  )
}

Confirmation.propTypes = {
  refid: PropTypes.string.isRequired,
  raffle: PropTypes.object.isRequired,
  confirmationMessage: PropTypes.string.isRequired,
  reEnterConfirmation: PropTypes.string,
}

Confirmation.defaultProps = {
  reEnterConfirmation: '',
}

export default Confirmation
