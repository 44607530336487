import {
  LinkOutlined,
  MailOutlined,
  NotificationTwoTone,
  PhoneOutlined,
} from '@ant-design/icons'
import { Divider, Typography } from 'antd'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import PropTypes from 'prop-types'
import React from 'react'

const SponsorDetails = ({
  company: {
    name: companyName,
    tagline,
    website,
  },
  email,
  name,
  phone,
}) => {
  const phoneNumber = parsePhoneNumberFromString(phone || '', 'US')

  return (
    <>
      <Typography.Text strong>
        {name}
      </Typography.Text>
      {companyName && (
        <>
          <Divider type="vertical" />
          <Typography.Text type="secondary">
            {companyName}
          </Typography.Text>
        </>
      )}
      <ul className="list-reset small">
        {tagline && (
          <li>
            <NotificationTwoTone className="mr1" twoToneColor="#fa541c" />
            {tagline}
          </li>
        )}
        {email && (
          <li>
            <MailOutlined className="mr1" />
            <a href={`mailto:${email}`}>
              {email}
            </a>
          </li>
        )}
        {phoneNumber && (
          <li>
            <PhoneOutlined className="mr1" />
            <a href={phoneNumber.getURI()}>
              {phoneNumber.formatNational()}
            </a>
          </li>
        )}
        {website && (
          <>
            <LinkOutlined className="mr1" />
            <a
              href={website.startsWith('http') ? website : `http://${website}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {website}
            </a>
          </>
        )}
      </ul>
    </>
  )
}

SponsorDetails.propTypes = {
  company: PropTypes.object,
  email: PropTypes.string,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string,
}

SponsorDetails.defaultProps = {
  company: {},
  email: undefined,
  phone: undefined,
}

export default SponsorDetails
