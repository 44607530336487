import { Collapse } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import RaffleUtils from '../../utils/raffle'

const RaffleDetailsFooter = ({ raffle }) => {
  const contestBackerCampaignOfficialRules = [
    'This is not a Live Campaign yet; you are pledging to become an Entrant if this Backer Campaign is successful.',
    'This is a Community Backer Campaign and NOT a Live Contest yet. '
    + 'Raffskie Backer Campaigns are specifically designed to determine whether or not there '
    + 'is sufficient demand or need for such a Contest. Each participating Backer will automatically '
    + 'be entered and receive an email if their Backer Campaign has met the minimum requirement for '
    + 'the Contest to go Live. Included in each email will be a receipt and a live link to play their '
    + 'contest. Each Contest will have Official Rules including eligibility requirements, '
    + 'how to participate and how to gift a Grand Prize. It will be up to each participant '
    + 'to monitor their junk mail box or spam folder for this email.',

    'How to be a Contest Backer - Enter your email address, fill in your name and some additional information'
      + ' using this online form. All information must be filled in correctly and needs to be accurate. Raffskie '
      + 'will use this information to notify the Backer as to the progress of their Campaign. By Entering to Win, '
      + 'you\'re agreeing to receive relevant Raffskie notifications.',

    'Privacy for Contest Backers - We won\'t ask you for personal information unless we truly'
      + ' need it to provide our services. We won\'t share your personal information with anyone except to comply with'
      + ' the law, develop new products, provide our services, or protect our rights.'
      + 'We don\'t store personal information on our servers unless it\'s required by law or for the on-going '
      + 'operation of one of our services.',

    'Credit card Pre-authorizations and Charges - Raffskie only charges a fee for its Contests and not '
      + ' for its Giveaways. '
      + 'All Contest backers will see a pre-authorization of funds at the time of becoming a Contest Backer. '
      + 'This pre-authorization only becomes a charge if the backer campaign has successfully met the minimum '
      + ' requirement in order to go Live. If the backer campaign does not reach '
      + 'the minimum requirement and is unsuccessful, your card is never charged. Your credit card '
      + 'details will be permanently deleted from our system. '
      + 'Once a Contest Backer Campaign is successful and has gone Live all Credit card pre-authorizations'
      + ' will be charged. '
      + 'After which time, Raffskie will send each participant a notification to the email address they entered. '
      + 'Included will be a receipt and a live link to play their contest.'
      + 'It will be up to each participant to monitor their junk mail box for this email.',

    'Receiving your Bonus Prize - Since Raffskie does not charge for a Contest Backer Campaign that has failed,'
      + ' only bonus prizes linked '
      + 'to successful contest backer campaigns will be sent out. Raffskie will mail the bonus prize to the address'
      + ' that was submitted during checkout. Raffskie is not responsible for any bonus prize lost in transit.',

    'Gifting your Bonus Prize - Raffskie will allow all Participants to gift their Bonus Prize. '
      + 'During checkout just enter the name and address where your Bonus Prize is to be mailed. '
      + 'Raffskie is not responsible for any bonus prize lost in transit.',

    'Gifting the potential Grand Prize - Raffskie will allow all Winners of the Grand Prize to Gift their Prize to any'
      + ' Family member or Friend. Arrangements can be made with Raffskie admin staff.',

    'Eligibility Requirements for Contest Backers - This Contest Backer Campaign is only open to backers who are'
      + ' 18 years of age or older as of the date backed.'
      + 'Is only open to residents of the selected state, city, county or country listed in the '
      + 'description. Successful Contest Backer Campaigns where '
      + 'the eventual prize is pharmaceutical a valid prescription is required from a licensed physician.',

    'Social Media Release for Contest Backers - This Contest Backer Campaign hosted by Raffskie is in no way'
      + ' sponsored, endorsed, administered by, or associated with Facebook, Instagram, Twitter or Pinterest. '
      + 'The Raffskie Backer Campaigns are completely Independent of Facebook. You the Backer '
      + 'acknowledge and agree to release all parties.',
  ]

  const giveawayBackerCampaignOfficialRules = [
    'This is not a Live Campaign yet; you are pledging to become an Entrant if this Backer Campaign is successful.',
    'This is a Community Backer Campaign and NOT a Live Giveaway yet. '
    + 'Raffskie Backer Campaigns are specifically designed to determine whether or not '
    + 'there is sufficient demand or need for such a Giveaway. Each participating Backer '
    + 'will automatically be entered if their Backer Campaign has met the minimum requirement '
    + 'for the Giveaway to go Live. Each Giveaway will have Official Rules including eligibility '
    + 'requirements, how to participate and how to gift a Grand Prize. It will be up to each '
    + 'participant to monitor their junk mail box or spam folder for this email.',

    'How to be a Giveaway Backer - Enter your email address, fill in your name and some additional information'
      + ' using this online form. All information must be filled in correctly and needs to be accurate. Raffskie '
      + 'will use this information to notify the Backer as to the progress of their Campaign. By Entering to Win,'
      + 'you\'re agreeing to receive relevant Raffskie notifications.',

    'Privacy for Giveaway Backers - We won\'t ask you for personal information unless we truly'
      + ' need it to provide our services. We won\'t share your personal information with anyone except to comply with'
      + ' the law, develop new products, provide our services, or protect our rights.'
      + 'We don\'t store personal information on our servers unless it\'s required by law or for the on-going '
      + 'operation of one of our services.',

    'Receiving your Grand Prize - Since a Giveaway Backer Campaign is NOT a Live Giveaway yet, only Grand Prizes'
      + ' linked to successful backer campaigns will be awarded.',

    'Gifting the potential Grand Prize - Raffskie will allow all Winners of the Grand Prize to Gift their Prize '
      + ' to any Family member or Friend. Arrangements can be made with Raffskie admin staff.',

    'Eligibility Requirements for Giveaway Backers - This Giveaway Backer Campaign is only open to backers who are'
      + ' 18 years of age or older as of the date backed.'
      + 'Is only open to residents of the selected state, city, county or country listed in the description'
      + ' Successful Backer Campaigns where '
      + 'the eventual prize is pharmaceutical a valid prescription is required from a licensed physician.',

    'Social Media Release for Giveaway Backers - This Giveaway Backer Campaign hosted by Raffskie is in no way'
      + ' sponsored, endorsed, administered by, or associated with Facebook, Instagram, Twitter or Pinterest. '
      + 'The Raffskie Backer Campaigns are completely Independent of Facebook. You the Backer '
      + 'acknowledge and agree to release all parties.',
  ]

  const contestShortenedOfficialRules = [
    'How to Enter - Enter your email address, fill in your name and some additional information using this online'
      + ' form. All information must be filled in correctly and needs to be accurate. Raffskie will use this '
      + ' information to contact and identify the Winner. By Entering to Win, you\'re agreeing to'
      + ' receive relevant Raffskie notifications.',

    'Privacy for Contest Participants - We won\'t ask you for personal information unless we truly need it to provide'
      + ' our services. We won\t share your personal information with anyone except to comply with the law, develop new'
      + ' products, provide our services, or protect our rights. We don\'t store personal information on our servers '
      + 'unless it\'s required by law or for the on-going operation of one of our services.',

    'Purchase does not enhance chance of Winning - This is a skill-based contest. Void where Prohibited.'
      + ' Average Value of the Prize is outlined in the Description.',

    'Credit Card Charges - Once your credit card has been successfully charged you will receive a confirmation email '
      + ' from us. Included will be a receipt and a Live link to play the Raffskie Numbers Challenge. '
      + ' It will be up to each participant to monitor their spam or junk mail folder for this email.',

    'Receiving your Bonus Prize - Raffskie will mail the bonus prize to the address that was submitted during '
      + ' checkout. Raffskie is not responsible for bonus prizes lost in transit.',

    'Gifting your Bonus Prize - Raffskie will allow all Participants to gift their Bonus Prize. During checkout'
      + ' just enter the name and address where your Bonus Prize is to be mailed. Raffskie is not responsible for'
      + 'any bonus prize lost in transit.',

    'Gifting the potential Grand Prize - Raffskie will allow any Winner of the Grand Prize to Gift their Prize '
      + ' to any Family member or Friend. Arrangements can be made with Raffskie admin staff.',

    'Eligibility Requirements - This Contest is only open to entrants who are 18 years of age or older as of the '
      + ' date of entry. This Contest is only open to residents of the selected state, city,'
      + 'county or country listed in the description. Contests where the prize is pharmaceutical '
      + ' a valid prescription is required from a licensed physician.',

    'Social Media Release - This Contest hosted by Raffskie is in no way sponsored, endorsed, administered by, '
      + ' or associated with Facebook, Instagram, Twitter or Pinterest. The Raffskie Contests are completely '
      + ' Independent of Facebook. You the participant acknowledge and agree to release all parties.',
  ]

  const giveawayShortenedOfficialRules = [
    'No Purchase is Necessary.',
    'You can Gift the Grand Prize to any Family member or Friend.',
    'Purchase does not enhance chance of Winning.',
    'Void where Prohibited.',
    'Average Value of the Prize is outlined in the Description.',
    'Odds of Winning are directly related to the number of Entrants.',
    'How to Enter - Fill in your name, email address and an entry using this online form. '
      + 'All information must be filled in correctly and needs to be accurate. '
      + 'Raffskie will use this information to contact and identify the Winner.',
    'Privacy for Giveaway Participants - '
      + ' We won\'t ask you for personal information unless we truly need it to provide our services.'
      + ' We won\'t share your personal information with anyone except to comply with the law, develop new products,'
      + ' provide our services, or protect our rights. We don\'t store personal information on our servers unless '
      + ' it\'s required by law or for the on-going operation of one of our services.',
    'Eligibility Requirements - '
      + 'This Giveaway is only open to entrants who are 18 years of age or older as of the date of entry. '
      + 'This Giveaway is only open to residents of the selected state, city or county listed in the description. '
      + 'Giveaways where the prize is pharmaceutical a valid prescription is required from a licensed physician.',
    'AMOE is outlined in the Raffskie Official Rules.',
    'Social Media Release - This Giveaway hosted by Raffskie is in no way sponsored, endorsed, administered by, '
      + 'or associated with Facebook, Instagram, Twitter or Pinterest. '
      + 'The Raffskie Sweepstakes Giveaways are completely Independent of Facebook. '
      + 'You the Entrant acknowledge and agree to release all parties.',
    'See the Terms of Use for a full length description of the Raffskie Official Rules.',
  ]

  let rules = []
  if (RaffleUtils.isBackerCampaign(raffle)) {
    if (RaffleUtils.isPaid(raffle)) {
      rules = contestBackerCampaignOfficialRules
    } else {
      rules = giveawayBackerCampaignOfficialRules
    }
  } else if (RaffleUtils.isPaid(raffle)) {
    rules = contestShortenedOfficialRules
  } else {
    rules = giveawayShortenedOfficialRules
  }

  const renderHeading = () => {
    if (RaffleUtils.isBackerCampaign(raffle)) {
      if (RaffleUtils.isPaid(raffle)) {
        return 'comp.raffledetails.officialrules.contest-backer-campaign-official-rules'
      }
      return 'comp.raffledetails.officialrules.giveaway-backer-campaign-official-rules'
    }
    if (RaffleUtils.isPaid(raffle)) {
      return 'comp.raffledetails.officialrules.shortened-contest-official-rules'
    }
    return 'comp.raffledetails.officialrules.shortened-giveaway-official-rules'
  }

  return (
    <Collapse>
      <Collapse.Panel
        header={<FormattedMessage id={renderHeading()} />}
        key={renderHeading()}
      >
        <ul>
          {rules.map((rule) => (
            <li key={rule}>
              {rule}
            </li>
          ))}
        </ul>
      </Collapse.Panel>
    </Collapse>
  )
}

RaffleDetailsFooter.propTypes = {
  raffle: PropTypes.object.isRequired,
}

export default RaffleDetailsFooter
