import {
  Card,
  Carousel,
  Col,
  Row,
  Typography,
} from 'antd'
import React from 'react'
import { uniqueId } from 'lodash'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { FormattedMessage } from 'react-intl'

import VideoTestimonial from './VideoTestimonial.mp4'
import RaffleUtils from '../../utils/raffle'

const Testimonials = ({ raffle }) => {
  const bgcolor = {
    backgroundColor: '#f1f1f1',
  }

  const contestReasons = [
    'Amazing grand prizes',
    'So simple grandma could play',
    'Choose your own bonus prize',
    'Free shipping on all prizes',
    'No account or download needed, just email',
    'Gift any grand or bonus prize',
  ]

  const giveawayReasons = [
    'Amazing grand prizes',
    'So simple grandma could play',
    'Free shipping on all prizes',
    'No account or download needed, just email',
    'Gift any grand or bonus prize',
  ]

  const contentStyle = {
    color: 'black',
    textAlign: 'center',
    padding: 10,
  }

  const hrStyle = {
    border: 'none',
    width: '100px',
    height: '2px',
    backgroundColor: '#333',
  }

  return (
    <Row className="py4" type="flex" justify="center" style={bgcolor}>
      <Col xs={24} md={24} className="center pb2">
        <Typography.Title level={3} className="h3 xs-hide sm-hide">
          <FormattedMessage id="comp.raffledetails.winners.title.desktop" />
        </Typography.Title>
        <Typography.Title level={3} className="h3 md-hide lg-hide">
          <FormattedMessage id="comp.raffledetails.winners.title.mobile" />
        </Typography.Title>
        <hr style={hrStyle} />
      </Col>
      <Col xs={14} md={12} lg={8} className="center m2">
        <Card
          style={{ boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)' }}
          width={250}
          cover={(
            <ReactPlayer
              url={VideoTestimonial}
              controls
              width="100%"
              height="auto"
              className="react-player"
              style={{ border: '1px solid #ddd' }}
            />
)}
        >
          <div>
            <Carousel autoplay className="custom-carousel">
              <div style={contentStyle}>
                <p className="left-align">
                  &quot;I&apos;m happy I participated. Raffskie was fun, easy and reliable. I won a great prize&quot;
                </p>
                <p className="right">-Richard Shimo</p>
              </div>
              <div style={contentStyle}>
                <p className="left-align">
                  &quot;Raffskie was simple to use and was an effective engagement tool. After all everyone
                  enjoys winning something&quot;
                </p>
                <p className="right">-Eaglequest Golf</p>
              </div>
              <div style={contentStyle}>
                <p className="left-align">
                  &quot;If you&apos;re looking to add value to your customer relationship then Raffskie
                  has a powerful platform.&quot;
                </p>
                <p className="right">-Clipshotz</p>
              </div>
              <div style={contentStyle}>
                <p className="left-align">
                  &quot;Raffskie provides fun and entertaining contests that generate additional revenue.
                  It&apos;s a great way to drive new traffic and strengthen your existing customer
                  relationship.&quot;
                </p>
                <p className="right">-Musqueam Golf</p>
              </div>
            </Carousel>
          </div>
        </Card>
      </Col>
      <Col md={10} className="m2 px1 xs-hide sm-hide">
        <Card style={{ height: '100%', boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)', padding: 10 }}>
          <div className="left-align">
            <Typography.Title level={3} className="m2 pb2">
              <FormattedMessage id="comp.testimonials.why-try.desktop" />
            </Typography.Title>
            <ul>
              {RaffleUtils.isPaid(raffle) && (
                contestReasons.map((item) => (
                  <li key={uniqueId()} className="testimonials" style={{ marginBottom: 10, fontSize: 18 }}>
                    <Typography.Text strong>{item}</Typography.Text>
                  </li>
                ))
              )}
              {!RaffleUtils.isPaid(raffle) && (
                giveawayReasons.map((item) => (
                  <li key={uniqueId()} className="testimonials" style={{ marginBottom: 15, fontSize: 18 }}>
                    <Typography.Text strong>{item}</Typography.Text>
                  </li>
                ))
              )}
            </ul>
          </div>
        </Card>
      </Col>
    </Row>
  )
}

Testimonials.propTypes = {
  raffle: PropTypes.object.isRequired,
}

export default Testimonials
