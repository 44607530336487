import React from 'react'
import Visa from 'payment-icons/svg/flat/visa.svg'
import MasterCard from 'payment-icons/svg/flat/mastercard.svg'
import Discover from 'payment-icons/svg/flat/discover.svg'
import Amex from 'payment-icons/svg/flat/amex.svg'
import DinersClub from 'payment-icons/svg/flat/diners.svg'
import JCB from 'payment-icons/svg/flat/jcb.svg'
import UnionPay from 'payment-icons/svg/flat/unionpay.svg'

const Cards = () => {
  const style = { width: '35px', height: '30px', marginRight: '2px' }

  return (
    <div className="center">
      <img src={Visa} style={style} alt="Visa" />
      <img src={MasterCard} style={style} alt="MasterCard" />
      <img src={Discover} style={style} alt="Discover" />
      <img src={Amex} style={style} alt="Amex" />
      <img src={DinersClub} style={style} alt="DinersClub" />
      <img src={JCB} style={style} alt="JCB" />
      <img src={UnionPay} style={style} alt="UnionPay" />
    </div>
  )
}

export default Cards
