import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Steps, Typography } from 'antd'
import {
  UserOutlined, SolutionOutlined, LoadingOutlined, ShareAltOutlined, HomeOutlined,
} from '@ant-design/icons'
import PaymentForm from '../PaymentForm'
import PersonalInfo from './PersonalInfo'
import Confirmation from '../Confirmation'
import BonusPrize from './BonusPrize'
import RaffleUtils from '../../utils/raffle'

const { Step } = Steps

const EntrantForm = ({
  email, onViewChange, raffle, refid, refcode, FormStep, previousPayments, oldCustomer, reEnterConfirmation,
  selectProduct,
}) => {
  const [step, setStep] = useState(FormStep)
  const [customer, setCustomer] = useState(oldCustomer)
  const [referral, setReferral] = useState(refid)
  const [messageId, setMessageId] = useState('')

  const handleStepChange = ({
    step: newStep, customer: newCustomer, refid: newRefid, messageId: newMessageId,
  }) => {
    if (newStep === -1) {
      onViewChange({ view: 'details' })
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    if (newCustomer) { setCustomer(newCustomer) }
    if (newRefid) { setReferral(newRefid) }
    if (newMessageId) { setMessageId(newMessageId) }
    setStep(newStep)
  }

  const stages = [
    {
      content: <PersonalInfo
        email={email}
        onStepChange={handleStepChange}
        raffle={raffle}
        refcode={refcode}
        customer={customer}
      />,
      icon: <UserOutlined />,
    },
    {
      content: <BonusPrize
        email={email}
        onStepChange={handleStepChange}
        raffle={raffle}
        refcode={refcode}
        customer={customer}
      />,
      icon: <HomeOutlined />,
    },
    {
      content: <PaymentForm
        onStepChange={handleStepChange}
        step={step}
        raffle={raffle}
        customer={customer}
        previousPayments={previousPayments}
        selectProduct={selectProduct}
      />,
      icon: <SolutionOutlined />,
      loadingicon: <LoadingOutlined />,
    },
    {
      title: 'Confirmation',
      content: <Confirmation
        raffle={raffle}
        refid={referral}
        confirmationMessage={messageId}
        reEnterConfirmation={reEnterConfirmation}
      />,
      icon: <ShareAltOutlined />,
      loadingicon: <LoadingOutlined />,
    },
  ]

  let steps = []
  if (raffle.raffleType !== 'paid') {
    steps = [stages[0], stages[3]]
  } else if (selectProduct.noBonusPrize || selectProduct.eGift) steps = [stages[0], stages[2], stages[3]]
  else steps = stages

  return (
    <>
      <Steps current={step} className={`${RaffleUtils.isPaid(raffle) ? 'contest' : 'giveaway'}`}>
        {steps.map(({
          title, content, icon,
        }, i) => (
          <Step
            key={title || `Step ${i + 1}`}
            title={title || `Step ${i + 1}`}
            className={i === step ? 'fade-in' : ''}
            icon={icon}
          >
            {content}
          </Step>
        ))}
      </Steps>
      <div className="steps-content">
        <Typography.Text strong>
          {RaffleUtils.isPaid(raffle) && (
          <p className="center">
            {`$ ${selectProduct.price} - ${selectProduct.gamechances} Chances - 
            ${selectProduct.product || '(No Bonus Prize)'}`}
          </p>
          )}
        </Typography.Text>
        {steps[step].content}
      </div>
    </>
  )
}

EntrantForm.propTypes = {
  email: PropTypes.string.isRequired,
  onViewChange: PropTypes.func.isRequired,
  raffle: PropTypes.object.isRequired,
  refcode: PropTypes.string,
  refid: PropTypes.string,
  FormStep: PropTypes.number.isRequired,
  previousPayments: PropTypes.object,
  oldCustomer: PropTypes.object,
  reEnterConfirmation: PropTypes.string,
  selectProduct: PropTypes.object.isRequired,
}

EntrantForm.defaultProps = {
  previousPayments: {},
  refcode: '',
  refid: '',
  oldCustomer: {},
  reEnterConfirmation: '',
}

export default EntrantForm
