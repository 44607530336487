import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'

function CardSection({ onChange }) {
  return (
    <CardElement onChange={onChange} />
  )
}

CardSection.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default CardSection
