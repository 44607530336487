import {
  Typography,
  Row,
  Col,
} from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ReactPlayer from 'react-player'
import gameDemo from '../../assets/videos/gamedemo.mp4'

const SneakPeak = () => {
  const hrStyle = {
    border: 'none',
    width: '200px',
    height: '2px',
    backgroundColor: '#333',
  }

  return (
    <Row type="flex" justify="center" className="py4">
      <Col xs={18} className="center py2">
        <Typography.Title level={3}>
          <FormattedMessage id="comp.sneakpeak.title" />
        </Typography.Title>
        <hr style={hrStyle} />
        <Typography.Text>
          <FormattedMessage id="comp.sneakpeak.desc" />
        </Typography.Text>
      </Col>
      <Col xs={20} md={18}>
        <Row justify="center" className="mt3">
          <Col md={11}>
            <ReactPlayer
              url={gameDemo}
              controls
              width="100%"
              height="auto"
              className="react-player"
              style={{ border: '1px solid #ddd' }}
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SneakPeak
