import { Radio } from 'antd'
import { capitalize } from 'lodash'
import React, { forwardRef, useState } from 'react'

// eslint-disable-next-line react/prop-types
const GenderSelect = ({ onChange, value }, ref) => {
  const [gender, setGender] = useState(value)

  const handleChange = (event) => {
    const newGender = event.target.value
    setGender(newGender)

    if (onChange) {
      onChange(newGender)
    }
  }

  return (
    <Radio.Group
      onChange={handleChange}
      ref={ref}
      size="large"
      value={gender}
    >
      {['male', 'female'].map((gen) => (
        <Radio key={gen} value={gen}>
          {capitalize(gen)}
        </Radio>
      ))}
    </Radio.Group>
  )
}

export default forwardRef(GenderSelect)
