import {
  message,
} from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import RaffleActions from '../../actions/raffles'
import ShippingForm from '../ShippingForm'

const BonusPrize = ({
  customer, onStepChange, raffle,
}) => {
  const [loading, setLoading] = useState(false)
  const [seconds, setSeconds] = useState(15)
  const [showAd, setShowAd] = useState(false)

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      setSeconds((currentSeconds) => currentSeconds - 1)
    }, 1000)

    const timeoutTimer = setTimeout(() => {
      setShowAd(false)
    }, 15 * 1000)

    return () => {
      clearInterval(intervalTimer)
      clearTimeout(timeoutTimer)
    }
  }, [])

  const handleBack = () => {
    onStepChange({ step: 0 })
  }

  const handleFinish = async (values) => {
    const backer = { ...values, email: customer.email }

    try {
      setLoading(true)
      await RaffleActions.updateBackerShipping(raffle, backer)
      setLoading(false)
      onStepChange({ step: 2, customer: { ...customer, type: 'backer', shipping: values } })
    } catch (ex) {
      message.warn(ex.message)
    }
  }

  if (showAd) {
    return (
      <>
        <FormattedMessage id="comp.entrantform.form-after-ad" values={{ seconds }} />
      </>
    )
  }

  return (
    <ShippingForm onFinish={handleFinish} loading={loading} handleBack={handleBack} customer={customer} />
  )
}

BonusPrize.propTypes = {
  customer: PropTypes.object.isRequired,
  onStepChange: PropTypes.func.isRequired,
  raffle: PropTypes.object.isRequired,
}

export default BonusPrize
