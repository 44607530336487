import PropTypes from 'prop-types'
import React from 'react'

// Google uploaded display ads specifications
// https://support.google.com/google-ads/answer/1722096
const Advertisement = ({ className, unit }) => {
  const cdn = 'https://rsk-ads.now.sh'
  const units = [
    // image ads
    { name: 'half-page ad', size: '300x600', src: `${cdn}/half-page-ad.gif` },
    { name: 'inline rectangle', size: '300x250', src: `${cdn}/inline-rectangle.jpg` },
    { name: 'large leaderboard', size: '970x90', src: `${cdn}/large-leaderboard.jpg` },
    // { name: 'large rectangle', size: '336x280' },
    { name: 'leaderboard', size: '728x90', src: `${cdn}/leaderboard.jpg` },
    { name: 'mobile banner', size: '320x50', src: `${cdn}/mobile-banner.jpg` },
    // video ads
    { name: 'video', src: `${cdn}/nike_480p.mp4` },
  ]

  const ad = units.find((u) => u.name === unit)
  if (ad) {
    if (ad.name === 'video') {
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video autoPlay className="block mx-auto" width="100%">
          <source src={ad.src} />
        </video>
      )
    }

    return (
      <img
        alt="raffskie ad"
        className={`block mx-auto mt3 ${className}`}
        src={ad.src}
      />
    )
  }

  return null
}

Advertisement.propTypes = {
  className: PropTypes.string,
  unit: PropTypes.string.isRequired,
}

Advertisement.defaultProps = {
  className: undefined,
}

export default Advertisement
