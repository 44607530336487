import Cleave from 'cleave.js/react'
import moment from 'moment'
import React, { forwardRef } from 'react'

// eslint-disable-next-line react/prop-types
const BirthdateInput = ({ onChange, value }, ref) => {
  const dateFormat = 'YYYY-MM-DD'

  const handleChange = (event) => {
    if (onChange) {
      const date = moment.utc(event.target.value.trim(), dateFormat, true)

      // must be at least 18 years old to enter the raffle
      const is18YearsOld = (moment().utc() - date) >= moment.duration(18, 'years')
      if (date.isValid() && is18YearsOld) {
        onChange(date.unix())
      } else {
        onChange(undefined)
      }
    }
  }

  return (
    <Cleave
      className="ant-input ant-input-lg"
      options={{
        date: true,
        datePattern: ['Y', 'm', 'd'],
        delimiter: '-',
      }}
      onChange={handleChange}
      placeholder={dateFormat}
      ref={ref}
      value={value ? moment.unix(value).format(dateFormat) : ''}
    />
  )
}

export default forwardRef(BirthdateInput)
