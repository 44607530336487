import {
  Row,
  Col,
  Collapse,
} from 'antd'
import React from 'react'
import PropTypes from 'prop-types'

import RaffleUtils from '../../utils/raffle'

import ContestDetails from './ContestDetails'
import RaffleDetailsFooter from './RaffleDetailsFooter'

const RaffleFAQ = ({ raffle }) => {
  const lastRow = {
    backgroundColor: '#f1f1f1',
  }

  return (
    <Row justify="center" type="flex" style={lastRow}>
      <Col md={18} xs={20} className="my2">
        <RaffleDetailsFooter raffle={raffle} />
      </Col>
      {RaffleUtils.isPaid(raffle) && (
        <Col md={18} xs={20} className="my2">
          <ContestDetails />
        </Col>
      )}
      <Col md={18} xs={20} className="my2">
        <Collapse bordered={false}>
          <Collapse.Panel
            header={`What do you do with my information ${RaffleUtils.isPaid(raffle) ? 'and credit card info?' : '?'}`}
            key={`What do you do with my information ${RaffleUtils.isPaid(raffle) ? 'and credit card info?' : '?'}`}
          >
            <p>
              Privacy comes first and foremost at Raffskie. First, we have a few fundamental principles at Raffskie:
            </p>
            <ul>
              <li>
                {' '}
                We won&apos;t ask you for personal information unless we truly need it to provide
                our services.
              </li>
              <li>
                {' '}
                We won&apos;t share your personal information with anyone except to comply with the law,
                develop new products, provide our services, or protect our rights.
                {' '}
              </li>
              <li>
                {' '}
                We don&apos;t store personal information on our servers unless it&apos;s required by law or
                for the on-going operation of one of our services.
                {' '}
              </li>
            </ul>
            <br />
            See
            {' '}
            <a href="/privacy-policy">Privacy Policy</a>
            .
          </Collapse.Panel>
          {RaffleUtils.isPaid(raffle) && (
            <>
              <Collapse.Panel
                header="When is my credit card charged when participating in a Live Contest?"
                key="When is my credit card charged when participating in a Live Contest?"
              >
                <p>
                  Your credit card is charged the moment you participate in a Live Contest. The only time we wait to
                  charge your credit card is when you&apos;re participating a Backer Contest. Backer Contests must be
                  successful before we charge your credit card.
                </p>
              </Collapse.Panel>
              <Collapse.Panel
                header="Do I receive a receipt for my participation in a Live Contest?"
                key="Do I receive a receipt for my participation in a Live Contest?"
              >
                <p>
                  Yes, all entrants receive a receipt for their participation in a Live Contest.
                </p>
              </Collapse.Panel>
              <Collapse.Panel
                header="If a Contest Backer Campaign fails, will my credit card still be charged?"
                key="If a Contest Backer Campaign fails, will my credit card still be charged?"
              >
                <p>
                  If the Contest Backer Campaign does not reach the minimum requirement and is unsuccessful, your
                  card is never charged. Your credit card details will be permanently deleted from our system.
                </p>
              </Collapse.Panel>
            </>
          )}
          <Collapse.Panel
            header="How am I notified if I win?"
            key="How am I notified if I win?"
          >
            <p>
              The winner will be contacted and validated via the email address they submitted.
            </p>
          </Collapse.Panel>
          <Collapse.Panel
            header="How do I unsubscribe from receiving Raffskie's emails?"
            key="How do I unsubscribe from receiving Raffskie's emails?"
          >
            <p>
              You can find an unsubscribe link at the bottom of all our marketing emails. By unsubscribing you will
              NOT be notified of future Raffskie Contests or Giveaways in your area.
            </p>
            <p>
              Please Note: Some of Raffskies correspondence are transactional in nature. Therefore, these emails do
              not provide an unsubscribe function.
            </p>
          </Collapse.Panel>
          <Collapse.Panel
            header="How can I contact Raffskie?"
            key="How can I contact Raffskie?"
          >
            <p>
              If you&apos;re having trouble and haven&apos;t found your answer in our FAQ&apos;s, contact us by email or
              mail to us at 45041 Ocean Park, Surrey, BC V4A 9L1, Canada.
            </p>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  )
}

RaffleFAQ.propTypes = {
  raffle: PropTypes.object.isRequired,
}

export default RaffleFAQ
