import {
  Checkbox,
  Input,
  Radio,
  Row,
} from 'antd'
import { isEmpty } from 'lodash'
import React, { forwardRef, useState } from 'react'

// eslint-disable-next-line react/prop-types
const QuestionInput = ({ onChange, question: { choices, question, type }, value = {} }, ref) => {
  const [answer, setAnswer] = useState(value.answer)

  const handleChange = (event) => {
    // for radio and input, value is from event.target
    // for checkbox, value is from event directly (array of strings)
    let newAnswer
    if (event.target) {
      newAnswer = event.target.value
    } else {
      newAnswer = event
    }

    setAnswer(newAnswer)

    if (onChange) {
      // returns an object with question and answer to parent form
      if (isEmpty(newAnswer)) {
        onChange(undefined)
      } else {
        onChange({
          answer: newAnswer,
          question,
        })
      }
    }
  }

  const renderChoices = () => {
    if (type === 'multiple-choice') {
      return (
        <Radio.Group
          onChange={handleChange}
          size="large"
          value={answer}
        >
          {/* eslint-disable-next-line react/prop-types */}
          {choices.map((choice) => (
            <div key={choice}>
              <Radio value={choice}>
                {choice}
              </Radio>
              <br />
            </div>
          ))}
        </Radio.Group>
      )
    }

    if (type === 'checkboxes') {
      return (
        <Checkbox.Group
          onChange={handleChange}
          size="large"
          value={answer}
        >
          {/* eslint-disable-next-line react/prop-types */}
          {choices.map((choice) => (
            <div key={choice} className="mb2">
              <Checkbox value={choice}>
                {choice}
              </Checkbox>
              <br />
            </div>
          ))}
        </Checkbox.Group>
      )
    }

    // commentbox
    return (
      <Input.TextArea
        maxLength={16384}
        onChange={handleChange}
        rows={4}
        value={answer}
      />
    )
  }

  return (
    <div ref={ref}>
      <Row style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
        {question}
      </Row>
      <Row>
        {renderChoices()}
      </Row>
    </div>
  )
}

export default forwardRef(QuestionInput)
