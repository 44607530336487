import {
  Button,
  Col,
  Divider,
  Empty,
  List,
  Row,
} from 'antd'
import { CreditCardFilled, LeftOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import './paymentstyles.css'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { capitalize, isEmpty } from 'lodash'
import { errorCodes } from '../../constants/raffle'

import RaffleUtils from '../../utils/raffle'
import amplify from '../../assets/conf/amplify.json'

import CheckoutForm from './CheckoutForm'

const stripePromise = loadStripe(`${amplify.stripePublicKey}`)

const PaymentForm = ({
  onStepChange, raffle, customer, previousPayments, selectProduct, step,
}) => {
  const [selectPay, setSelectPay] = useState(selectProduct)

  const handleSelectPay = async (product) => {
    setSelectPay(product)
  }
  const renderCard = (brand, last4, expMonth, expYear) => (
    <>
      <span className="card"><CreditCardFilled /></span>
      <span className="card">{capitalize(brand)}</span>
      <span className="card">
        ••••
        {last4}
      </span>
      <span className="card">
        Expiry:
      </span>
      <span className="card">
        {expMonth}
        /
        {expYear}
      </span>
    </>
  )

  const renderErrorCode = (error) => (
    <span className="error">{errorCodes[error].error}</span>
  )

  const renderSuccess = (status) => (
    <span className="success">{capitalize(status)}</span>
  )

  const handleBack = () => {
    if (selectProduct.noBonusPrize || selectProduct.eGift) onStepChange({ step: 0 })
    else onStepChange({ step: 1 })
  }

  return (
    <div className="m1">
      {!raffle.products && (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      {!isEmpty(raffle.products) && isEmpty(selectPay) && (
      <div>
        {raffle.products.map((product, index) => (
          <Row key={product.price} className="center" type="flex" justify="center">
            <Col xs={24} md={6}>
              <img
                alt={product.product}
                src={RaffleUtils.getGameLogo()}
                style={{ width: 100, height: 80 }}
              />
            </Col>
            <Col xs={24} md={10}>
              <b>
                {`${product.gamechances} ${product.gamechances > 1 ? 'Chances' : 'Chance'} 
                                            to play for the Grand prize at $${product.price}`}
              </b>
              <br />
              {product.product && (
                'No Bonus Prize'
              )}
              {!product.isBonusPrize && (
                product.description
              )}
            </Col>
            <Col xs={24} md={6} className="xs-center">
              <div>
                <Button type="primary" onClick={() => handleSelectPay(product, index)}>
                  Select
                </Button>
              </div>
            </Col>
          </Row>
        ))}
        <Button icon={<LeftOutlined />} onClick={() => onStepChange({ step: 1 })}>
          <span>
            <FormattedMessage id="comp.entrantform.back" />
          </span>
        </Button>
      </div>
      )}
      {!isEmpty(selectPay) && (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            onStepChange={onStepChange}
            raffle={raffle}
            product={selectPay}
            customer={customer}
            goBack={handleBack}
            step={step}
          />
        </Elements>
      )}
      {!isEmpty(previousPayments) && (!isEmpty(previousPayments.history.data) && (
        <>
          <Divider orientation="left">Previous Payments</Divider>
          <List
            itemLayout="vertical"
            pagination={{ pageSize: 3 }}
            dataSource={previousPayments.history.data}
            renderItem={(item) => (!isEmpty(item.last_payment_error) && (
            <List.Item>
              <List.Item.Meta
                title={`Amount: $${parseInt(item.amount, 10) / 100}`}
                description={renderErrorCode(item.last_payment_error.code)}
              />
              {renderCard(item.last_payment_error.payment_method.card.brand,
                item.last_payment_error.payment_method.card.last4,
                item.last_payment_error.payment_method.card.exp_month,
                item.last_payment_error.payment_method.card.exp_year)}
              <p>
                Message:
                {` ${item.last_payment_error.message}`}
              </p>
              <p>
                Attempted at
                {` ${moment.unix(item.created).format('DD-MM-YYYY h:mm:ss')}`}
              </p>
            </List.Item>
            ))
              || (isEmpty(item.last_payment_error) && (
              <List.Item>
                <List.Item.Meta
                  title={`Amount: $${parseInt(item.amount, 10) / 100}`}
                  description={renderSuccess(item.status)}
                />
                <p>
                  Description:
                  {` ${item.description}`}
                </p>
                <p>
                  Attempted at
                  {` ${moment.unix(item.created).format('DD-MM-YYYY h:mm:ss')}`}
                </p>
              </List.Item>
              ))}
          />
        </>
      ))}
    </div>
  )
}

PaymentForm.propTypes = {
  raffle: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  onStepChange: PropTypes.func.isRequired,
  previousPayments: PropTypes.object.isRequired,
  selectProduct: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
}

export default PaymentForm
