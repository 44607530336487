import React, { useState } from 'react'
import { LeftOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Form,
  Input,
  Card,
  Divider,
  Select,
} from 'antd'
import { capitalize } from 'lodash'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import SubmitButton from '../SubmitButton'

import { countries, states } from '../../constants/raffle'

const ShippingForm = ({
  onFinish, loading, handleBack, customer,
}) => {
  const [country, setCountry] = useState(customer.shipping.country || 'United States')

  const handleChangeCountry = (value) => {
    setCountry(value)
  }

  return (
    <Card>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item>
          <Alert
            message={<FormattedMessage id="comp.entrantform.address.alert" />}
            showIcon
            type="info"
          />
        </Form.Item>
        <Divider orientation="left" plain>Shipping Address</Divider>
        <Form.Item
          initialValue={customer.shipping.name || ''}
          label={<FormattedMessage id="common.address.name" />}
          name="name"
          rules={[{ required: true }]}
        >
          <Input maxLength={128} size="large" />
        </Form.Item>
        <Form.Item
          initialValue={customer.shipping.line1 || ''}
          label={<FormattedMessage id="common.address.street1" />}
          name="line1"
          rules={[{ required: true }]}
        >
          <Input maxLength={128} size="large" />
        </Form.Item>
        <Form.Item
          initialValue={customer.shipping.line2 || ''}
          label={<FormattedMessage id="common.address.street2" />}
          name="line2"
        >
          <Input maxLength={128} size="large" />
        </Form.Item>
        <Form.Item
          initialValue={customer.shipping.city || ''}
          label={<FormattedMessage id="common.address.city" />}
          name="city"
          normalize={(value) => capitalize(value)}
          rules={[{ required: true }]}
        >
          <Input maxLength={128} size="large" />
        </Form.Item>
        <Form.Item
          initialValue={capitalize(customer.shipping.state) || ''}
          label={<FormattedMessage id="common.address.state" />}
          name="state"
          normalize={(value) => capitalize(value)}
          rules={[{ required: true }]}
        >
          <Select showSearch>
            {states[country].map((t) => (
              <Select.Option key={t} value={t}>
                {t}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {country === 'Canada' && (
          <Form.Item
            initialValue={customer.shipping.zip || ''}
            label={<FormattedMessage id="common.address.zip" />}
            name="zip"
            normalize={(value) => value.toUpperCase()}
            rules={[{
              required: true,
            },
            {
              pattern: new RegExp(/^([a-zA-Z]\d[a-zA-Z]) {0,1}(\d[a-zA-Z]\d)$/),
              message: 'Invalid Postal Code',
            },
            ]}
          >
            <Input maxLength={128} size="large" />
          </Form.Item>
        )}
        {country === 'United States' && (
          <Form.Item
            initialValue={customer.shipping.zip || ''}
            label={<FormattedMessage id="common.address.zip" />}
            name="zip"
            normalize={(value) => capitalize(value)}
            rules={[{
              required: true,
            },
            {
              pattern: new RegExp(/^\d{5}$/),
              message: 'Invalid Zip Code',
            },
            ]}
          >
            <Input maxLength={128} size="large" />
          </Form.Item>
        )}
        <Form.Item
          label={<FormattedMessage id="common.address.country" />}
          name="country"
          rules={[{ required: true }]}
          initialValue={customer.shipping.country || country}
        >
          <Select onChange={handleChangeCountry} showSearch>
            {Object.keys(countries).map((t) => (
              <Select.Option key={t} value={t}>
                <FormattedMessage id={`common.address.country.${t}`} />
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button.Group size="large">
            <Button icon={<LeftOutlined />} onClick={handleBack}>
              <span>
                <FormattedMessage id="comp.entrantform.back" />
              </span>
            </Button>
            <SubmitButton
              loading={loading}
              messageId="comp.next-step"
            />
          </Button.Group>
        </Form.Item>
      </Form>
    </Card>
  )
}

ShippingForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
}

export default ShippingForm
