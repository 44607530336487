import {
  Result,
  Skeleton,
  message,
} from 'antd'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import RaffleActions from '../actions/raffles'
import Layout from '../components/Layout'
import RaffleDetails from '../components/RaffleDetails'
import RaffleUtils from '../utils/raffle'

const RafflePage = ({ location }) => {
  // entrant email is collected in sidebar and passed to the form
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(true)
  const [raffle, setRaffle] = useState()
  const [view, setView] = useState('details')
  const [refid, setRefid] = useState('')
  const [FormStep, setFormStep] = useState(0)
  const [previousPayments, setPreviousPayments] = useState({})
  const [oldCustomer, setOldCustomer] = useState({})
  const [reEnterConfirmation, setReEnterConfirmation] = useState('')
  const [selectProduct, setSelectProduct] = useState({})

  // get raffle id from the location object or the query string
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const id = location.state ? location.state.id : params.id
  const industry = location.state ? location.state.industry : params.industry
  const refcode = location.state ? location.state.refcode : params.refcode

  const getRaffle = useCallback(
    async () => {
      try {
        setLoading(true)
        const newRaffle = await RaffleActions.get(industry, id)
        setLoading(false)

        setRaffle(newRaffle)
      } catch (ex) {
        message.warn(ex.message)
      }
    },
    [id, industry],
  )

  useEffect(() => {
    if (view === 'details') {
      setFormStep(0)
      setOldCustomer({})
      setPreviousPayments({})
      setEmail('')
      setRefid('')
    }
    if (id && industry) {
      // get raffle details
      getRaffle()
    } else {
      // use empty object since the raffle get() will return an empty object if not found
      setRaffle({})
      setLoading(false)
    }
  }, [getRaffle, id, industry, view])

  const handleViewChange = ({
    email: newEmail, view: newView, refid: newRefid,
    previousPayments: newPayments, oldCustomer: newCustomer,
    FormStep: newFormStep, selectProduct: newSelectProduct,
  }) => {
    if (newEmail) {
      setEmail(newEmail)
    }
    if (newRefid) {
      setRefid(newRefid)
    }
    if (newPayments) {
      setPreviousPayments(newPayments)
    }
    if (newCustomer) {
      setOldCustomer(newCustomer)
    }
    if (newSelectProduct) {
      setSelectProduct(newSelectProduct)
    }
    if (newFormStep) {
      if ((newSelectProduct.noBonusPrize || newSelectProduct.eGift) && newFormStep === 2) {
        setReEnterConfirmation('comp.contest.success.re-enter.subtitle')
      } else if (RaffleUtils.isPaid(raffle) && newFormStep === 3) {
        setReEnterConfirmation('comp.contest.success.re-enter.subtitle')
      } else if (!RaffleUtils.isPaid(raffle) && newFormStep === 1) {
        setReEnterConfirmation('comp.contest.success.re-enter.subtitle')
      }
      setFormStep(newFormStep)
    }
    setView(newView)
  }

  const renderView = () => {
    // still loading
    if (loading) {
      return (
        <Skeleton />
      )
    }

    // after loading, the raffle is still empty
    // show 404 error message
    if (isEmpty(raffle)) {
      return (
        <Result
          status="404"
          subTitle={<FormattedMessage id="page.404.sorry" />}
          title="404"
        />
      )
    }

    // render raffle details
    return (
      <RaffleDetails
        email={email}
        onViewChange={handleViewChange}
        raffle={raffle}
        view={view}
        refcode={refcode}
        refid={refid}
        FormStep={FormStep}
        selectProduct={selectProduct}
        previousPayments={previousPayments}
        oldCustomer={oldCustomer}
        reEnterConfirmation={reEnterConfirmation}
      />
    )
  }

  return (
    <Layout customStyle={{ backgroundColor: 'white', marginTop: '64px' }}>
      <div className="">
        {renderView()}
      </div>
    </Layout>
  )
}

RafflePage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default RafflePage
