import {
  TrophyOutlined,
  GiftOutlined,
  FieldTimeOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from '@ant-design/icons'
import {
  Col,
  Row,
  Button,
  Divider,
  Form,
  message,
  notification,
  Select,
  Typography,
} from 'antd'
import EmailValidator from 'email-validator'
import { isEmpty, uniqueId } from 'lodash'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import RaffleActions from '../../actions/raffles'
import RaffleUtils from '../../utils/raffle'

import TermCheckbox from './TermCheckbox'
import EntrantCount from './EntrantCount'

import RaffleFAQ from './RaffleFAQ'
import SneakPeak from './SneakPeak'
import SponsorDetails from '../RaffleSidebar/SponsorDetails'
import './style.css'
import Testimonials from './Testimonials'
import TrustSeals from '../TrustSeals'

const NewRaffleDescription = ({
  raffle,
  refcode,
  onViewChange,
}) => {
  const intl = useIntl()
  const enterToWinRef = useRef(null)
  const [showMore, setShowMore] = useState(false)

  const rowFour = {
    backgroundColor: '#b7e7f9',
    width: '100%',
  }

  const hrStyle = {
    border: 'none',
    width: '100px',
    height: '2px',
    backgroundColor: '#333',
  }

  const CTAStyle = {
    border: '2px solid',
    borderRadius: '50px',
    backgroundColor: '#facb2d',
    boxShadow: '10px 10px #808080',
    color: 'black',
    cursor: 'pointer',
    fontSize: 20,
    fontFamily: 'PT Sans',
    fontWeight: '600',
    height: '65px',
    width: '200px',
    marginTop: '20px',
    outline: 'none',
  }

  const linkStyle = {
    background: 'none',
    border: '1px solid black',
    borderRadius: '25px',
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '5px',
    cursor: 'pointer',
    width: 130,
  }

  const handleSubmit = async (values) => {
    const email = values.email.trim().toLowerCase()
    if (email && EmailValidator.validate(email)) {
      try {
        onViewChange({ view: 'loading' })
        let resp
        let view
        // submit entrant email first
        if (RaffleUtils.isBackerCampaign(raffle)) {
          resp = await RaffleActions.addBacker(raffle, email, refcode)
          view = 'backerform'
        } else {
          resp = await RaffleActions.addEntrant(raffle, email, refcode)
          view = resp.type === 'backer' ? 'backerform' : 'form'
        }

        let nextStep = 0
        let selectProduct = {}
        if (RaffleUtils.isPaid(raffle)) {
          selectProduct = raffle.products[values.product]

          // Checking for paid product
          if (resp.product) {
            if (resp.noBonusPrize || resp.eGift) {
              if (resp.entries === 2) selectProduct = resp.product
            } else if (resp.entries === 3) selectProduct = resp.product
          }

          // Checking if the user has finished the game
          if (selectProduct.noBonusPrize || selectProduct.eGift) {
            if (resp.entries === 4) nextStep = 2
            else nextStep = resp.entries > 1 ? resp.entries - 1 : resp.entries

            if (nextStep === 2) {
              if (resp.payment !== 'succeeded') nextStep = 1
            }
          } else {
            nextStep = resp.entries > 3 ? 3 : resp.entries

            if (nextStep === 3) {
              if (resp.payment !== 'succeeded') nextStep = 2
            }
          }
        } else {
          nextStep = resp.entries
        }

        if (resp.payment) {
          const refid = resp.referralId || ''
          // Customer Already entered, checking for payments
          const token = localStorage.getItem('payment_hash') || ''
          let payments = {}
          if (token !== '' && 'customerId' in resp) {
            try {
              payments = await RaffleActions.getAttemptedPayments(resp.customerId, token)
            } catch (e) {
              // Catches exception caused by unauthorized call
              // Do nothing
            }
          }

          // Setting payment step in form
          onViewChange({
            view,
            email,
            refid,
            previousPayments: payments,
            oldCustomer: { ...resp, shipping: resp.shipping || {} },
            FormStep: nextStep,
            selectProduct,
          })
        } else {
          // if new customer, show the form from step 0
          onViewChange({
            view, email, selectProduct, FormStep: 0,
          })
        }
      } catch (ex) {
        onViewChange({ view: 'details' })
        const { response } = ex
        if (response.status === 409) {
          notification.success({
            message: intl.formatMessage({ id: 'common.thank-you' }),
            description: intl.formatMessage({ id: 'comp.rafflesidebar.entertowininput.already-entered' }),
          })
        } else {
          message.warn(ex.message)
        }
      }
    } else {
      const msg = intl.formatMessage({ id: 'comp.rafflesidebar.invalidemail' })
      message.warn(msg)
    }
  }

  return (
    <>
      <Row justify="center" className="m2" align={!showMore ? 'middle' : 'top'}>
        <Col md={12} xs={22} className="m2">
          <Row>
            <Col md={24} xs={24}>
              <Typography.Title level={2}>
                {raffle.title}
              </Typography.Title>
              {RaffleUtils.isPaid(raffle) && (
                <span style={{ fontWeight: 500 }}>
                  <FormattedMessage id="comp.raffledetails.bonus-prize" />
                </span>
              )}
              <ul className="list-reset mt1">
                <li>
                  {RaffleUtils.isPaid(raffle) && (
                  <TrophyOutlined className="mr1" />
                  )}
                  {!RaffleUtils.isPaid(raffle) && (
                  <GiftOutlined className="mr1" />
                  )}
                  {(RaffleUtils.isBackerCampaign(raffle) && (
                  <span>
                    <FormattedMessage id="common.backer" />
                  </span>
                  ))
                  || (!RaffleUtils.isBackerCampaign(raffle) && (
                  <span>
                    <FormattedMessage id="common.live" />
                  </span>
                  ))}
                  <Typography.Text style={{ marginLeft: 3 }}>
                    <FormattedMessage id={`common.raffle-type.${raffle.raffleType}`} />
                  </Typography.Text>
                  <Divider type="vertical" />
                  <FieldTimeOutlined className="mr1" />
                  <span>
                    <FormattedMessage
                      id="page.raffle.days"
                      values={{
                        days: RaffleUtils.getRemainingDays(
                          // for backer campaign, submission ends at the start date of the raffle
                          RaffleUtils.isBackerCampaign(raffle) ? raffle.startAt : raffle.endAt,
                        ),
                      }}
                    />
                    {' '}
                    Left
                  </span>
                  <Divider type="vertical" />
                  <EntrantCount count={parseInt(raffle.entrantCount, 10) + parseInt(raffle.backerCount, 10)} />
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="mt2">
            <Col md={22} xs={22}>
              {!showMore && (
              <div>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: raffle.description.slice(0, 555) }} />
                <button
                  style={linkStyle}
                  onClick={() => setShowMore(!showMore)}
                  type="button"
                >
                  <FormattedMessage id="common.show-more" />
                  <CaretDownOutlined />
                </button>
              </div>
              )}
              {showMore && (
              <div>
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: raffle.description }} />
                {!isEmpty(raffle.sponsors) && (
                <div className="mb3">
                  <Divider messageId="common.sponsoredby" />
                  <ul className="list-reset">
                    {raffle.sponsors.map((sponsor) => {
                      // sponsor basic info takes precedence
                      const {
                        company,
                        email,
                        firstname,
                        lastname,
                        phone,
                      } = sponsor
                      const key = uniqueId()

                      return (
                        <li key={key} className="mb2">
                          <SponsorDetails
                            company={company}
                            email={email}
                            name={RaffleUtils.getSponsorFullName(firstname, lastname)}
                            phone={phone}
                          />
                        </li>
                      )
                    })}
                  </ul>
                </div>
                )}
                <button
                  onClick={() => { setShowMore(!showMore); window.scrollTo({ top: 0, behavior: 'smooth' }) }}
                  style={linkStyle}
                  type="button"
                >
                  <FormattedMessage id="common.show-less" />
                  <CaretUpOutlined />
                </button>
              </div>
              )}
            </Col>
          </Row>
          <Row className="xs-hide sm-hide">
            <Col>
              <button
                style={CTAStyle}
                type="button"
                onClick={() => {
                  const y = (enterToWinRef.current.getBoundingClientRect().y - 150) + window.pageYOffset
                  window.scrollTo({ top: y, behavior: 'smooth' })
                }}
              >
                <FormattedMessage id="comp.indexbanner.enter-to-win" />
              </button>
            </Col>
          </Row>
          <Row justify="center" className="md-hide lg-hide">
            <Col>
              <button
                style={CTAStyle}
                type="button"
                onClick={() => {
                  const y = (enterToWinRef.current.getBoundingClientRect().y - 150) + window.pageYOffset
                  window.scrollTo({ top: y, behavior: 'smooth' })
                }}
              >
                <FormattedMessage id="comp.indexbanner.enter-to-win" />
              </button>
            </Col>
          </Row>

        </Col>
        <Col md={10} className="center sm-hide xs-hide">
          <img
            alt={raffle.title}
            src={RaffleUtils.getImageSrc(raffle)}
            style={{ width: '90%' }}
          />
        </Col>
      </Row>

      <Testimonials raffle={raffle} />

      {RaffleUtils.isPaid(raffle) && (
        <SneakPeak />
      )}

      <div ref={enterToWinRef}>
        <Row type="flex" justify="center" className="py2" style={rowFour}>
          <Col xs={24} className="center py2">
            <Typography.Title level={3}>
              <FormattedMessage id="common.start" />
            </Typography.Title>
            <hr style={hrStyle} />
          </Col>
          <Col md={10} xs={23} className="center">
            <div className="details pb2 center enter-email mx-auto">
              <Form
                onFinish={handleSubmit}
                initialValues={{
                  email: '',
                }}
              >
                {RaffleUtils.isPaid(raffle) && (
                  <Form.Item
                    name="product"
                    rules={[
                      { required: true },
                    ]}
                  >
                    <Select
                      className="raffle custom-dropdown"
                      placeholder="Choose your chances and bonus prize"
                    >

                      {raffle.products.map((product, index) => (
                        <Select.Option key={`${product.price}-${product.product || ''}`} value={index}>
                          {`$ ${product.price} - ${product.gamechances} Chances - 
                          ${product.product || '(No Bonus Prize)'}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  name="email"
                  className="email-input"
                >
                  <input placeholder="you@example.com" size="50" />
                </Form.Item>
                <Button htmlType="submit" className="enter">
                  {!RaffleUtils.isBackerCampaign(raffle) && (
                  <FormattedMessage id="comp.indexbanner.enter-to-win" />
                  )}
                  {RaffleUtils.isBackerCampaign(raffle) && (
                  <FormattedMessage id="comp.indexbanner.back-this-campaign" />
                  )}
                </Button>
                <Form.Item
                  name="agreement"
                  rules={[
                    {
                      required: true,
                      transform: (value) => (value || undefined),
                      type: 'boolean',
                    },
                  ]}
                  valuePropName="checked"
                >
                  <TermCheckbox />
                </Form.Item>
                <p className="mt2">
                  <FormattedMessage id="comp.raffledetails.shipping-info" />
                </p>
                {RaffleUtils.isBackerCampaign(raffle) && (
                  <p>
                    <FormattedMessage id="comp.raffledetails.backer-pledge" />
                  </p>
                )}
                <TrustSeals />
              </Form>
            </div>
          </Col>
        </Row>
      </div>

      <RaffleFAQ raffle={raffle} />
    </>
  )
}

NewRaffleDescription.propTypes = {
  raffle: PropTypes.object.isRequired,
  refcode: PropTypes.string,
  onViewChange: PropTypes.func.isRequired,
}

NewRaffleDescription.defaultProps = {
  refcode: '',
}

export default NewRaffleDescription
